import Vue from 'vue'
import Vuex from 'vuex'
import Question from '../models/question'

Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    data: [] as Question[],
  },

  mutations: {
    setQuestions(state, questions: Question[]) {
      state.data = questions
    },
  }

})

export default store