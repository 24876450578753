
import { Component, Vue, Emit } from "vue-property-decorator";
import HttpClient from "@/services/httpClient";
import BasicUtil from "@/services/basicUtil";
import StorageService from "../services/storage";
import Question from "../models/question";
import Category from "../models/category";

@Component({
  components: {}
})
export default class HomeView extends Vue {
  private dao: HttpClient = new HttpClient();
  private StorageService: StorageService = new StorageService()
  private isAllow: boolean = BasicUtil.isAllow();
  private grade: string = process.env.VUE_APP_GRADE;
  private questions: Question[][] = [];

  mounted() {
    new BasicUtil().removeBasicSetting();
    new StorageService().removeCurrentData();
    const categories = this.dao.getCategories();
    for (var i = 0; i < categories.length; i++) {
      const category = categories[i];
      const questions = this.dao.getRontenLessonFromCategory(category);
      this.questions.push(questions);
    }
  }

  private getCount(categoryId: number) {
    return this.StorageService.getIncorrectDataCount(categoryId)
  }

  private hasReexam(categoryId: number) {
    const c =  this.getCount(categoryId)
    return c > 0
  }

  private exec(id: string, category: Category, lessonNo: number) {
    const datas = this.dao.getRontenQuestionsFromCategory(category, lessonNo);
    // const datas = this.dao.getQuestion();
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + id);
  }

  private execJissen(category: Category) {
    const datas = this.dao.getJissenQuestionsFromCategory(category);
    // const datas = this.dao.getQuestion();
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + datas[0].id);
  }

  private random(category: Category) {
    const datas = this.dao.getRandomQuestionsFromCategory(category, 10);
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + datas[0].id);
  }
  private allExec(category: Category) {
    const datas = this.dao.getAllQuestionsFromCategory(category);
    this.$store.commit("setQuestions", []);
    this.$store.commit("setQuestions", datas);
    new StorageService().setCurrentData(datas);
    new BasicUtil().setBasicSetting();
    this.$router.push("/question/" + datas[0].id);
  }
}
