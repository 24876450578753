
import { Component, Prop, Vue, Emit } from "vue-property-decorator";

import StorageService from "../services/storage";

@Component
export default class Header extends Vue {
  $refs: any = {
    btnDropdownRef: null,
    popoverDropdownRef: null
  };
  private title: string = ""
  private dropdownPopoverShow:boolean = false
  created() {
    switch (process.env.VUE_APP_GRADE)
    {
      case "3":
        this.title = "FP3級"
        break
      case "2":
        this.title = "FP2級"
        break
    }
  }
  mounted() {}
  private reset() {
    if (!window.confirm("本当に学習履歴をクリアしますか？")) return;
    new StorageService().removeData("incorrect")
    location.href = "/"
  }
}
