import Question from "@/models/question";
import HttpClient from "./httpClient";

export default class StorageService {
  public setData(key: string, data: any) {
    localStorage.setItem(key, JSON.stringify(data));
  }

  public getData(key:string) {
    const data = localStorage.getItem(key);
    if (data == null || data == undefined) {
      this.setData(key, [])
      return []
    }
    return JSON.parse(data)
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public setCorrectData(id: string) {
    const data: History[] = this.getData("incorrect")
    const index = data.findIndex(d => {
      return d.i == id
    })
    if (index >= 0) {
      data.splice(index, 1)
    }
    this.setData("incorrect", data)
  }

  public setIncorrectData(id: string, categoryId: number) {
    const data: History[] = this.getData("incorrect")
    const index = data.findIndex(d => {
      return d.i == id
    })
    const history = new History()
    history.i = id
    history.d = Date.now()
    history.c = categoryId
    if (index >= 0) {
      data.splice(index, 1)
    }
    data.push(history)
    this.setData("incorrect", data)
  }

  public getIncorrectDataCount(categoryId: number) {
    const data = this.getData("incorrect")
    const ret = data.filter((d:History) => {
      return d.c == categoryId
    })
    return ret.length
  }
  public getIncorrectDataId(id: string) {
    const data = this.getData("incorrect")
    return data.find((d: History) => {
      return d.i == id
    })
  }
  public getIncorrectData() {
    const data = this.getData("incorrect")
    return data
  }

  private formatDate (date: Date, format: string) {
    format = format.replace(/yyyy/g, String(date.getFullYear()));
    format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
    format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
    format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
    format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
    format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
    format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
    return format;
  }

  public isBookmark(id: string) {
    const data: History[] = this.getData("bookmark")
    const index = data.findIndex(d => {
      return d.i == id
    })
    return index >= 0
  }

  public setBookmarkData(id: string) {
    const data: History[] = this.getData("bookmark")
    const index = data.findIndex(d => {
      return d.i == id
    })
    const history = new History()
    history.i = id
    history.d = Date.now()
    if (index >= 0) {
      data.splice(index, 1)
    } else {
      data.push(history)
    }
    this.setData("bookmark", data)
  }

  public getBookmarkData(dao: HttpClient) {
    const data = this.getData("bookmark")
    const ret: HistoryQuestion[] = []
    data.reverse().forEach((d: History) => {
      const hq = new HistoryQuestion()
      hq.question = dao.getQuestionFromId(d.i)
      if (hq.question == null) return
      hq.date = this.formatDate(new Date(d.d), "yyyy/MM/dd")
      ret.push(hq)
    })
    return ret
  }

  public getCurrentData(): Question[] {
    const json = sessionStorage.getItem('currentData')
    return json != null ? JSON.parse(json) : []
  }

  public setCurrentData(data: Question[]) {
    sessionStorage.setItem('currentData', JSON.stringify(data));
  }

  public removeCurrentData() {
    sessionStorage.removeItem('currentData')
  }
}

export class History {
  public i: string = ""
  public c: number = 0
  public d: number = 0
}

export class HistoryQuestion {
  public question: Question|null = null
  public date: string = ""
}