
export default [
  {
    "id": 1,
    "name": "ライフプランニングと資金計画",
    "sort": 1,
  },
  {
    "id": 2,
    "name": "リスク管理",
    "sort": 2,
  },
  {
    "id": 3,
    "name": "金融資産運用",
    "sort": 3,
  },
  {
    "id": 4,
    "name": "タックスプランニング",
    "sort": 4,
  },
  {
    "id": 5,
    "name": "不動産",
    "sort": 5,
  },
  {
    "id": 6,
    "name": "相続・事業承継",
    "sort": 6,
  }
]