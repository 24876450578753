import Question from '@/models/question';
import axios from 'axios'
import BasicUtil from './basicUtil'
import question3 from './question_3'
import question2 from './question_2'
import theme3 from './theme_3';
import theme2 from './theme_2';
import lesson3 from './lesson_3';
import lesson2 from './lesson_2';
import Category from '@/models/category';
import Lesson from '@/models/lesson';

export default class HttpClient {

  private apiEndpoint: string = process.env.VUE_APP_API

  // public async saveResult(data: Result[]): Promise<void> {
  //   return axios.post(this.apiEndpoint + "/app",
  //     data,
  //     {
  //       headers: {
  //         sc: BasicUtil.getSc(),
  //       }
  //     })
  // }

  public getQuestion(): any {
    if (process.env.VUE_APP_GRADE == '3') {
      return JSON.parse(JSON.stringify(question3))
    } else if (process.env.VUE_APP_GRADE == '2') {
      return JSON.parse(JSON.stringify(question2))
    }
    return JSON.parse(JSON.stringify(question3))
  }

  public getCategories(): Category[] {
    if (process.env.VUE_APP_GRADE == '3') {
      return theme3 as Category[]
    } else if (process.env.VUE_APP_GRADE == '2') {
      return theme2 as Category[]
    }
    return []
  }

  public getLessons(): Lesson[] {
    if (process.env.VUE_APP_GRADE == '3') {
      return lesson3 as Lesson[]
    } else if (process.env.VUE_APP_GRADE == '2') {
      return lesson2 as Lesson[]
    }
    return []
  }

  public getRontenQuestionsFromCategory(category: Category, lessonNo: number): Question[] {
    let questions: Question[] = this.getQuestion()
    let lessons: Lesson[] = this.getLessons()
    if (lessonNo == 0) {
      questions = questions.filter(q => {
        return q.categoryId == category.id && q.type == 1
      })
    } else {
      questions = questions.filter(q => {
        return q.categoryId == category.id && q.lessonNo == lessonNo && q.type == 1
      })
    }

    if (category != undefined) {
      questions.forEach(q => {
        q.category = category
        const les = lessons.find(l => {
          return l.sort == q.lessonNo && l.categoryId == category.id
        })
        q.lesson = les ? les : new Lesson()
      })
    }
    return questions
  }

  public getRandomQuestionsFromCategory(category: Category, limit: number): Question[] {
    let questions = this.getRontenQuestionsFromCategory(category, 0)
    questions = this.shuffle(questions)
    return questions.slice(0, limit);
  }

  public getAllQuestionsFromCategory(category: Category): Question[] {
    let questions = this.getRontenQuestionsFromCategory(category, 0)
    // questions = this.shuffle(questions)
    return questions;
  }

  public getJissenQuestionsFromCategory(category: Category): Question[] {
    let questions: Question[] = this.getQuestion()
    let lessons: Lesson[] = this.getLessons()
    questions = questions.filter(q => {
      return q.categoryId == category.id && q.type == 2
    })

    if (category != undefined) {
      questions.forEach(q => {
        q.category = category
        const les = lessons.find(l => {
          return l.sort == q.lessonNo && l.categoryId == category.id
        })
        q.lesson = les ? les : new Lesson()
      })
    }
    return questions
  }

  shuffle<T>(array: T[]) {
    const out = Array.from(array);
    for (let i = out.length - 1; i > 0; i--) {
      const r = Math.floor(Math.random() * (i + 1));
      const tmp = out[i];
      out[i] = out[r];
      out[r] = tmp;
    }
    return out;
  }


  public getRontenLessonFromCategory(category: Category): Question[] {
    let questions: Question[] = this.getQuestion()
    let lessons: Lesson[] = this.getLessons()
    questions = questions.filter(q => {
      return q.categoryId == category.id && q.type == 1
    })

    questions.sort(function (a, b) {
      if (a.lessonNo < b.lessonNo) return -1;
      if (a.lessonNo > b.lessonNo) return 1;
      return 0;
    });
    let ret: Question[] = []
    let current = 0;
    if (category != undefined) {
      questions.forEach(q => {
        q.category = category
        if (q.lessonNo > current) {
          current = q.lessonNo
          const les = lessons.find(l => {
            return l.sort == current && l.categoryId == category.id
          })
          q.lesson = les ? les : new Lesson()
          ret.push(q)
        }
      })
    }
    return ret
  }

  public getIncrectQuestionsFromCategory(category: Category, history: History[], lessonNo: number): Question[] {
    let ids: string[] = []
    for (var i = 0; i < history.length; i++) {
      ids.push(history[i].i)
    }
    let questions: Question[] = this.getQuestion()
    let lessons: Lesson[] = this.getLessons()
    questions = questions.filter(q => {
      return q.categoryId == category.id && ids.includes(q.id) && q.lessonNo == lessonNo
    })

    if (category != undefined) {
      questions.forEach(q => {
        q.category = category
        const les = lessons.find(l => {
          return l.sort == q.lessonNo && l.categoryId == category.id
        })
        q.lesson = les ? les : new Lesson()
      })
    }
    return questions
  }


  public getQuestionFromId(id: string) {
    const shiken = this.getQuestion()
    const categories = this.getCategories()
    let questions: Question[] = shiken.questions
    const question = questions.find(q => {
      return q.id == id
    })
    if (question == undefined) return null
    question!.category = categories.find(c => {
      return c.id == question?.categoryId
    })
    return question
  }

  public postData(grade: string, id: string, type: number) {
    axios.post("https://nboki-api-bestlicense-p5ypmgh3pq-an.a.run.app/web-fp/" + grade + "/" + id + "/" + String(type), null, {
      headers: {
        "X-Token": "PyPV2YZB.D%hVRnBQcW~&d&rb~aN6t!u"
      }
    })
  }

  public getServerData(grade: string, id: string) {
    return axios.get("https://nboki-api-bestlicense-p5ypmgh3pq-an.a.run.app/web-fp/" + grade + "/" + id, {
      headers: {
        "X-Token": "PyPV2YZB.D%hVRnBQcW~&d&rb~aN6t!u"
      }
    })
  }
}

export class History {
  public i: string = ""
  public c: number = 0
  public d: number = 0
}