
import { Component, Vue } from "vue-property-decorator";
import HttpClient from "@/services/httpClient";
import Header from "@/components/Header.vue";
import BasicUtil from './services/basicUtil'
@Component({
  components: {
    Header
  }
})
export default class App extends Vue {
  $refs: any = {
    header: Header
  };
  private dao: HttpClient = new HttpClient();
  private alreadyAuth1: boolean = false

  created() {
    
  }

  mounted() {
    if (this.$route.name != 'Auth') {

    this.alreadyAuth1 = BasicUtil.isAuth(process.env.VUE_APP_GRADE)
    if (!this.alreadyAuth1) this.$router.push("/auth")
    }
    
    const agent = window.navigator.userAgent.toLowerCase();
    if (agent.indexOf("msie") != -1 || agent.indexOf("trident") != -1) {
    } else if (agent.indexOf("edg") != -1 || agent.indexOf("edge") != -1) {
    } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    } else if (agent.indexOf("chrome") != -1) {
    } else if (agent.indexOf("safari") != -1) {
      window.onpopstate = function(event: any) {
        window.location.href = "/?error=back";
      };
    } else if (agent.indexOf("firefox") != -1) {
    } else if (agent.indexOf("opr") != -1 || agent.indexOf("opera") != -1) {
    }

  }
}
